import {Route} from 'react-router';
import {Layout} from "components/Layout";
import {Playground} from "pages/playground";


function App() {

    return (
        <Layout>
            <switch>
                <Route exact path={"/playground"}>
                    <Playground />
                </Route>
            </switch>
        </Layout>
    );
}

export default App;
