import { CircularProgress } from '@mui/material';

export function Loader() {
  return (
    <CircularProgress
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
      }}
    />
  );
}
