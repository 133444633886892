import React, { lazy, Suspense } from 'react';

const Editor = lazy(
  async () => {
    const ace = await import('react-ace');
    await Promise.all([
      import('ace-builds/src-noconflict/mode-javascript'),
      import('ace-builds/src-noconflict/mode-json'),
      import('ace-builds/src-noconflict/theme-xcode'),
    ]);
    return ace;
  }
);

type Props = {
  value: string;
  mode: 'json' | 'javascript';
  name: string;
  minLines: number;
  maxLines: number;
  readOnly?: boolean;
  onChange?: (val: string) => void;
}

export const CodeEditor = ({
  value,
  mode,
  name,
  minLines,
  maxLines,
  readOnly,
  onChange
}: Props) => (
  <Suspense fallback={<>Loading...</>}>
    <Editor
      readOnly={readOnly}
      mode={mode}
      theme="xcode"
      value={value}
      onChange={onChange}
      name={name}
      width="100%"
      minLines={minLines}
      maxLines={maxLines}
      editorProps={{ $blockScrolling: false }}
      setOptions={{
        useWorker: false,
      }}
    />
  </Suspense>
)