import { toast } from 'react-toastify';
import { Api } from "api/client";
import { baseUrl } from "ConfigLoaderWrapper";

const apiClient = (() => {
  console.log("client baseurl: " + baseUrl)
  return new Api({
    baseURL: baseUrl,
    securityWorker: (securityData: { token: string } | null) => {
      if (securityData) {
        return {
          headers: {
            'Authorization': `Bearer ${ securityData.token }`,
          },
        };
      }
    },
  })
})()

apiClient.instance.interceptors.response.use(
  undefined,
  (error: { response: { data: { detail: string } } }) => {
    toast.error(error.response?.data.detail);
    throw new Error(error?.response?.data?.detail);
  },
);


export { apiClient };
