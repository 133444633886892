import {Field, Form, Formik, useFormikContext} from "formik";
import {Alert, Button} from "@mui/material";
import {FormikHelpers} from "formik/dist/types";
import {CodeEditor} from "components/CodeEditor";
import {useState} from "react";
import {AxiosResponse} from "axios";
import {generateTemplate} from "api/customClient";


const FileInput = () => {
    const formikProps = useFormikContext()
    return <div>
        <label htmlFor="template" style={ { marginRight: 10 } }>
            Template file
        </label>
        <input
            id="template"
            type="file"
            multiple
            onChange={ (event) => {
                // @ts-ignore
                formikProps.setFieldValue("templates", event.currentTarget.files);
            }}
        />
    </div>
}

// @ts-ignore
function base64ToBlob(base64, mimetype) {
    if (!window.atob || !window.Uint8Array) {
        // The current browser doesn't have the atob function. Cannot continue
        return null;
    }
    mimetype = mimetype || '';
    const slicesize = 512;
    var bytechars = atob(base64);
    var bytearrays = [];
    for (var offset = 0; offset < bytechars.length; offset += slicesize) {
        var slice = bytechars.slice(offset, offset + slicesize);
        var bytenums = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            bytenums[i] = slice.charCodeAt(i);
        }
        var bytearray = new Uint8Array(bytenums);
        bytearrays[bytearrays.length] = bytearray;
    }
    return new Blob(bytearrays, {type: mimetype});
};

// @ts-ignore
export const createLocalFile = (data, filename, mimetype) => {
    if (window && window.URL) {
        const res = base64ToBlob(data,mimetype)
        const csvURL = window.URL.createObjectURL(res);
        const tempLink = document.createElement('a');
        document.body.appendChild(tempLink);
        tempLink.setAttribute('type', 'hidden');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', filename);
        tempLink.click();
        tempLink.remove();
    }
    return null;
};

function findFileName(contentDisposition: string) {
    return contentDisposition.substring(contentDisposition.indexOf("filename=") + "filename=".length);
}

export const Playground = () => {

    type FormValues = {
        templates: File[] | null,
        data: string
    }


    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

    const onSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        try {
            JSON.parse(values.data)
        } catch (e) {
            setErrorMsg("data json is incorrect")
        }
        setErrorMsg(undefined)

        if (values.templates == null) {
            return
        }
        try {
            const blob: AxiosResponse<File> = await generateTemplate({
                templates: values.templates,
                data: values.data
            })
            console.log(blob)
            const filename = findFileName(blob.headers["content-disposition"])
            createLocalFile(blob.data, filename, undefined)
        } catch (e) {
            setErrorMsg((e as any).toString())
        }
    }

    // @ts-ignore
    const CodeEditorForm = ({ field, form, ...props}) => {
        return (
            <CodeEditor {...props} {...field} onChange={(value) => {
                form.setFieldValue(field.name, value)
            }} />
        );
    }

    return <div>
        { errorMsg && <div>
            <Alert severity="error">{ errorMsg }</Alert>
        </div>}

        <Formik initialValues={ {
            templates: null,
            data: '{}'
        } }
            // @ts-ignore
                onSubmit={ onSubmit }>
            <Form style={ { marginLeft: 10 } }>
                <div style={ {
                    fontSize: 30
                } }>Test contract
                </div>
                <div style={ { marginTop: 10, marginBottom: 10 } }>
                    <FileInput />
                </div>
                <Button type="submit" color='primary' variant="contained"
                        style={ { marginTop: 10, marginBottom: 10 } }> Submit </Button>
                <Field
                    mode="json"
                    id="data"
                    name="data"
                    minLines={10}
                    component={CodeEditorForm}
                />
{/*                <CodeEditor
                    mode="json"
                    value={ '{}' }
                    onChange={ (value: string) => {
                    } }
                    name="event-editor"
                    minLines={ 10 }
                    maxLines={ 20 }
                />*/}
            </Form>
        </Formik>


    </div>
}