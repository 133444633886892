import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {ToastContainer} from 'react-toastify';
import {ThemeProvider} from '@mui/material';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider, useAuth0} from '@auth0/auth0-react';
import {BrowserRouter} from 'react-router-dom';
import {apiClient} from './api';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import theme from 'config/theme';
import AuthWrapper from "wrappers/AuthWrapper";
import {Loader} from "components/Loader";
import {useAppConfig} from "ConfigLoaderWrapper";

export {};
declare global {
    interface Window {
        appConfig: any;
    }
}

const ThisApp = () => {
    const {
        error,
        logout,
        isAuthenticated,
        getAccessTokenSilently,
        isLoading: auth0IsLoading,
    } = useAuth0();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function initApiClient() {
            setIsLoading(true);
            const accessToken = await getAccessTokenSilently();
            apiClient.setSecurityData({ token: accessToken });
            setIsLoading(false);
        }

        if (isAuthenticated) {
            initApiClient();
        }
        if (!auth0IsLoading && !isAuthenticated) {
            setIsLoading(false);
        }
    }, [isAuthenticated, auth0IsLoading, getAccessTokenSilently]);

    useEffect(() => {
        if (error) {
            const handle = setTimeout(() => {
                logout();
            }, 3000);
            return () => clearTimeout(handle);
        }
    }, [error, logout]);

    if (isLoading) return <Loader/>;

    if (error) {
        return (
            <div>
                <span>{ `${ error.name }:${ error.message } Please use the company domain email and/or ask for access.` }</span>
            </div>
        );
    }

    return (
        <ThemeProvider theme={ theme }>
            <BrowserRouter>
                <AuthWrapper>
                    <App/>
                    <ToastContainer/>
                </AuthWrapper>
            </BrowserRouter>
        </ThemeProvider>
    );
};

const AppWithConfig = () => {

    const config = useAppConfig()

    if (!config) {
        return <div/>
    } else {
        console.log("Going to " + window.appConfig.auth0domain)
        return <Auth0Provider
            domain={ window.appConfig.auth0domain }
            clientId={ window.appConfig.auth0clientId }
            redirectUri={ window.location.origin }
            audience={ window.appConfig.audience }
            cacheLocation="localstorage"
        >
            <ThisApp/>
        </Auth0Provider>
    }
}

ReactDOM.render(
    <React.StrictMode>
        <AppWithConfig/>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
