import { MenuItem } from 'react-pro-sidebar';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { UrlObject } from 'url';

type Props = {
  children?: React.ReactNode;
  href: string | UrlObject;
  icon?: React.ReactNode;
};

export const ActiveLink = ({ children, href, icon }: Props) => {
  const match = useRouteMatch(href.toString());
  return (
    <li>
      <Link to={href.toString()}>
        <MenuItem icon={icon} active={match?.isExact}>
          {children}
        </MenuItem>
      </Link>
    </li>
  );
};
