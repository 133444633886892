import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { CgProfile } from 'react-icons/cg';
import { useAuth0 } from '@auth0/auth0-react';

import { ActiveLink } from './ActiveLink';
import 'react-pro-sidebar/dist/css/styles.css';

const Wrapper = styled.div`
  position: fixed;
  width: 200px;
`;

const Title = styled.div`
  p {
    font-size: 20px;
    padding: 0 20px;
    color: #fff;
  }
`;

const StyledProSidebar = styled(ProSidebar)`
  height: 100vh;
  width: 100%;
  min-width: 280px;
  .pro-sidebar-inner {
    background-color: #1a4063;
  }

  .pro-icon-wrapper {
    color: white;
    background: transparent !important;
  }

  .pro-menu a {
    &::before {
      position: relative;
    }
  }

  .active {
    background-color: #3a77b0;
  }
`;

const Name = styled.div`
  text-align: center;
`;

const Email = styled(Name)`
  font-size: 0.8em;
`;

export const Sidebar = () => {
  const { user, logout } = useAuth0();

  return (
    <Wrapper>
      <StyledProSidebar collapsed={false}>
        <SidebarHeader>
          <Title>
            <Link to="/">
              <p>Contracts</p>
            </Link>
          </Title>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <ActiveLink href="/playground" icon={<FaFileInvoiceDollar />}>
              Playground
            </ActiveLink>
          </Menu>
        </SidebarContent>
        <Menu iconShape="square">
          <MenuItem
            icon={
              user?.picture ? (
                <img
                  src={user?.picture}
                  alt="User"
                  height="35px"
                  width="35px"
                />
              ) : (
                <CgProfile size="100" />
              )
            }
          >
            <div onClick={() => logout({ returnTo: window.location.origin })}>
              <SidebarFooter>
                <Name>{user?.name}</Name>
                <Email>{user?.email}</Email>
              </SidebarFooter>
            </div>
          </MenuItem>
        </Menu>
      </StyledProSidebar>
    </Wrapper>
  );
};