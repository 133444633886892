import styled from 'styled-components';
import { Sidebar } from './Sidebar';

type LayoutProps = {
  children: React.ReactNode;
};

const Wrapper = styled.main`
  margin-left: 280px;
`;

export const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Sidebar />
      <Wrapper>{children}</Wrapper>
    </>
  );
};
