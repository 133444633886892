import {GenerateContractWithTemplateRequest} from "api/client";
import { apiClient } from "api/index";
import {AxiosRequestConfig} from "axios";

const instance = apiClient.instance

async function commonParams(params: AxiosRequestConfig){
    const secure = true
    const secureParams =
        // @ts-ignore
        ((typeof secure === "boolean" ? secure : this.secure) &&
            // @ts-ignore
            apiClient.securityWorker &&
            // @ts-ignore
            (await apiClient.securityWorker(apiClient.securityData))) ||
        {};
    // @ts-ignore
    return apiClient.mergeRequestParams(params, secureParams);
}

export async function generateTemplate(generateContractWithTemplateRequest: GenerateContractWithTemplateRequest){

    const formData = new FormData()
    formData.append("data", generateContractWithTemplateRequest.data)

    for (const template of generateContractWithTemplateRequest.templates) {
        formData.append("templates[]", template)
    }

    return await instance.request(await commonParams({
        url: '/api/generator/generate-with-template',
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        data: formData
    }))
}